import React, { Component, useState, useEffect } from "react"
import { ReactTypeformEmbed } from 'react-typeform-embed';

export default function Results() {
  var formUrl = "https://szjbjhzaiaq.typeform.com/to/lkNmq7Ga";

  return (
      <ReactTypeformEmbed style={{height:'100%'}} popup={false} url={formUrl} />
  )
}
